import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import TopHeroComponent from "../components/herocomponents/topherocomponent"
import BrowseByCat from "../components/bodycomponents/browsebycat"
import CardComponent from "../components/bodycomponents/card-component"
import RED_BG from "../images/background-images/bg-red.svg"
import M_GRAY_BG from "../images/background-images/bg-medium-gray.svg"
import { colors } from "../utils/colors"
import EMPLOYEE_EQUIPMENT from "../images/buy-equipment/employee-inspecting-equipment.jpg"
import Accordion from "../components/bodycomponents/accordion"
import FAQ from "../components/faq-components/faq"
import ContactForm from "../components/bodycomponents/contact-form"
import { buyEquipmentFaqItems } from "../utils/data/faq"

const BuyEquipment = () => {
  const accordionItems = [
    {
      title: "1. Browse Inventory",
      content:
        "Explore our selection of lab, processing, and packaging equipment. Use search filters to quickly find the items that meet your needs.",
    },
    {
      title: "2. Request a Quote",
      content:
        "Click “Request a Quote” for personalized pricing and shipping estimates. We’ll guide you through logistics, ensuring a seamless buying experience.",
    },
    {
      title: "3. Place Your Order",
      content:
        "Once you’ve selected your equipment, our sales team will help finalize the purchase. We’re here to ensure a smooth and efficient transaction.",
    },
    {
      title: "4. Arrange Payment",
      content:
        "We accept certified checks, wire transfers, and credit cards for invoices under $10,000. Please note that we cannot accept purchase orders (POs) as payment.",
    },
    {
      title: "5. Delivery or Pickup",
      content:
        "Our sales team coordinates global shipping and logistics for your equipment, or arranges for local pickup, ensuring a hassle-free process from start to finish.",
    },
  ]
  return (
    <Layout>
      <SEO
        title={`High Quality Used Pharmaceutical & Biotech Instruments for Sale`}
        description={`Surplus Solutions carries a wide variety of used pharmaceutical lab, packaging, and processing equipment. Contact us to discuss your equipment needs.`}
      />
      <TopHeroComponent
        backgroundImage={RED_BG}
        backgroundColor={colors.scarletRed}
        fontShadowColor={colors.red}
        shadowColor="shadow-red-bottom"
        pageTitle="Buy Equipment"
        title="Buy New, Used, and Refurbished Lab Equipment"
        body={
          <p className="pt-4">
            Surplus Solutions offers high-quality lab, processing, and packaging
            equipment from top brands like Agilent, Thermo Fisher, and
            Sartorius. Our cost-effective options enhance efficiency, extend
            equipment lifespan, and support sustainability. Trust us for
            flexible buying and exceptional value.
          </p>
        }
      />
      <BrowseByCat title="Browse by Category" />
      <div className="bg-light-gray shadow-custom-in">
        <div className="container max-w-screen-xl py-14">
          <div className="text-center">
            <h2 className="pb-2">Why Choose Surplus Solutions?</h2>
            <p className="pb-10">
              Surplus Solutions provides high-quality lab, processing, and
              packaging equipment with a focus on reliability, efficiency, and
              cost-effectiveness. We prioritize your needs, offering thoroughly
              inspected equipment, straightforward purchasing options, and an
              experienced team that makes sure you get the right equipment for
              your specific needs.
            </p>
            <div className="grid gap-10 lg:grid-cols-3">
              <CardComponent
                title="Tested & Recertified Lab Equipment"
                body={
                  <p>
                    Every piece of equipment undergoes meticulous
                    decontamination, testing, repair, and servicing to ensure it
                    meets our strict quality standards. So you can be sure it’s
                    ready for immediate use.
                  </p>
                }
                backgroundColor={colors.white}
                shadow="shadow-scarletRed-bottom"
                padding="p-[35px] lg:p-[1.875rem]"
              />
              <CardComponent
                title={<>Savings Without <br/> Compromise</>}
                body={
                  <p>
                    Buying pre-owned equipment from Surplus Solutions offers
                    significant savings compared to new, so you get premium
                    quality while freeing up resources for other critical areas
                    of your business.
                  </p>
                }
                backgroundColor={colors.white}
                shadow="shadow-scarletRed-bottom"
                padding="p-[35px] lg:p-[1.875rem]"

              />
              <CardComponent
                title="Access to Premium Equipment"
                body={
                  <p>
                    At Surplus, we’re constantly acquiring and listing lab,
                    processing, and packaging equipment from trusted
                    manufacturers. Fast turnover means fresh inventory every
                    week, so there’s always something worth exploring.
                  </p>
                }
                backgroundColor={colors.white}
                shadow="shadow-scarletRed-bottom"
                padding="p-[35px] lg:p-[1.875rem]"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="shadow-custom-out">
        <div className="container w-full max-w-screen-xl py-14">
          <h2 className="text-center pb-2">
            Our Process for Buying and Selling Equipment
          </h2>
          <div className="w-full flex flex-col md:flex-row md:space-x-12 py-5 items-stretch">
            {/* Image Container */}
            <div className="w-full md:w-2/5">
              <img
                className="aspect-square object-cover rounded-[5px]"
                src={EMPLOYEE_EQUIPMENT}
                alt="Technician performing a detailed assessment of lab equipment for resale potential in a warehouse setting."
              />
            </div>
            {/* Accordion Container */}
            <div className="w-full md:w-3/5">
              <div className="flex flex-grow">
                <Accordion items={accordionItems} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FAQ
        title="FAQ"
        items={buyEquipmentFaqItems}
        backgroundColor={colors.lightGray}
        backgroundImage={M_GRAY_BG}
        shadowColor="shadow-mediumGray-top"
      />
      <ContactForm
        title="Need to sell equipment or find something specific?"
        body={
          <p>
            Surplus Solutions doesn’t just supply equipment—we also buy surplus
            lab, processing, and packaging equipment. We offer competitive
            prices and flexible selling options to suit your needs. If you have
            quality equipment to sell, reach out to our team today.
          </p>
        }
        fontShadowColor={colors.red}
        backgroundColor={colors.containerGray}
        shadow="shadow-red-bottom"
        formType="Equipment Sourcing"
      />
    </Layout>
  )
}

export default BuyEquipment
